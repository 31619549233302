<template>
  <div class="inquiry">
    <!-- FORM: BODY SECTION -->
    <div class="wFormContainer" >
      <div class="wFormHeader"></div>
      <div class=""><div class="wForm" id="4885985-WRPR" dir="ltr">
      <div class="codesection" id="code-4885985"></div>
      <h3 class="wFormTitle" id="4885985-T">Ahmed Roach Inquiry Form</h3>
      <form method="post" action="https://www.tfaforms.com/responses/processor" class="hintsBelow labelsAbove" id="4885985" role="form">
        <div class="oneField field-container-D    " id="tfa_2-D">
          <label id="tfa_2-L" class="label preField reqMark" for="tfa_2">Your Name</label><br><div class="inputWrapper"><input type="text" id="tfa_2" name="tfa_2" value="" aria-required="true" title="Your Name" class="required"></div>
        </div>
        <div class="oneField field-container-D    " id="tfa_4-D">
          <label id="tfa_4-L" class="label preField reqMark" for="tfa_4">Email</label><br><div class="inputWrapper"><input type="text" id="tfa_4" name="tfa_4" value="" aria-required="true" title="Email" class="validate-email required"></div>
        </div>
        <div class="oneField field-container-D    " id="tfa_3-D">
          <label id="tfa_3-L" class="label preField reqMark" for="tfa_3">Inquiry</label><br><div class="inputWrapper"><textarea aria-required="true" id="tfa_3" name="tfa_3" title="Inquiry" class="required"></textarea></div>
        </div>
        <div class="actions" id="4885985-A"><input type="submit" data-label="Submit" class="primaryAction" id="submit_button" value="Submit"></div>
        <div style="clear:both"></div>
        <input type="hidden" value="4885985" name="tfa_dbFormId" id="tfa_dbFormId"><input type="hidden" value="" name="tfa_dbResponseId" id="tfa_dbResponseId"><input type="hidden" value="8a6ac03c5dd1d485b9f5a0177817fb4b" name="tfa_dbControl" id="tfa_dbControl"><input type="hidden" value="" name="tfa_dbWorkflowSessionUuid" id="tfa_dbWorkflowSessionUuid"><input type="hidden" value="2" name="tfa_dbVersionId" id="tfa_dbVersionId"><input type="hidden" value="" name="tfa_switchedoff" id="tfa_switchedoff">
      </form>
    </div></div><div class="wFormFooter"><p class="supportInfo"><a target="new" class="contactInfoLink" href="https://www.tfaforms.com/forms/help/4885985">Contact Information</a><br></p></div>
      <p class="supportInfo" >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Inquiry'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
