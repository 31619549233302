<template>
  <div id="app">
    <img alt="Ahmed Roach" style="max-width: 25%" src="./assets/ahmedfloat.png">
    <HelloWorld msg="أحمد بن علي"/>
    <div class="footer">
      <p>Copyright &copy; 2021 Ahmed Roach</p>
    </div>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
</style>
